.container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-radius: 0.25rem;
}

.cta-1 {
  border: none !important;
  background-color: var(--Secondary) !important;
  color: var(--black) !important;
  width: 100% !important;
}

.cta-1:hover {
  transform: scale(1.01);
}

.cta-2 {
  width: 100% !important;
  color: var(--black) !important;
  background-color: var(--white) !important;
  border: 1px solid var(--black) !important;
}

.cta-2:hover {
  background-color: var(--Primary) !important;
  color: var(--white) !important;
  transform: scale(1.01);
  border-color: var(--white) !important;
}

.cta-btns-heading {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

.cta-btns-subheading {
  margin-top: -0.7rem !important;
  line-height: 1.2rem !important;
}

.white-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}
