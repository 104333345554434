.halo-wrapper {
  --inline-padding: 20px;
}

.img-wrapper {
  width: 100%;
  height: 100%;
}

.img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
}

.bg-image {
  position: absolute;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.5);
}

.halo-container {
  width: 100% !important;
  padding-inline: var(--inline-padding) !important;
  min-height: 45vh;
}

.white-color {
  color: var(--white) !important;
}

.breadcrumb-icon {
  margin-bottom: -5px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-text {
  color: rgb(193, 186, 186) !important;
}

.cta-wrapper {
  padding: 24px !important;
  border-radius: 0.25rem;
}

.white.cta-wrapper {
  background-color: var(--white);
}

@media only screen and (max-width: 900px) {
  .white.cta-wrapper {
    width: 100vw;
    border-radius: 0;
    transform: translateX(calc(-1 * var(--inline-padding)));
  }

  .halo-container {
    min-height: 66vh;
  }
}
